@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: onest;
  src: url(assets/fonts/Onest.ttf);
}
@font-face {
  font-family: dmsans;
  src: url(assets/fonts/DMSans.ttf);
}

@layer base {
  * {
    @apply box-border p-0 m-0 list-none outline-none font-onest;
  }
  :root {
    --hackathon-primary: #4247BE;
    --hackathon-dark: #111111;
    --hackathon-dark-1d: #1D1D1F;
    --hackathon-darkgray: #333333;
    --hackathon-gray-66: #666666;
    --hackathon-white: #ffffff;
  }

  html {
    @apply text-[62.5%] max-3xl:text-[48%] max-2xl:text-[43%];
  }

  body {
    @apply max-w-full overflow-x-hidden;
  }

  img,
  svg {
    width: 100%;
    height: 100%;
  }
}

@layer components {
  .border-red{
    @apply border border-red-600
  }
  .border-blue{
    @apply border border-blue-600
  }
  .card-overlay{
    @apply before:content-[""] before:w-full before:h-full before:bg-gradient-to-t before:from-[#000000a6] before:to-[#00000018] before:absolute before:top-0 before:left-0
  }
  .seperator{
    @apply before:content-[""] before:w-[1px] before:h-[90%] before:bg-[#D7D7D7]  before:absolute before:top-0 before:right-[-15%]
  }
}
@layer utilities {
 .banner-pattern{
  background-image: url(assets/images/banner-bg.png), url(assets/images/circle.png);
  background-repeat: no-repeat, no-repeat;
  background-size: cover, contain;
  background-position: center, top right;
 }
 .amazed-pattern{
  background-image: url(assets/images/lines.png);
  background-repeat: no-repeat;
  background-size: 100% 500px;
  background-position: bottom;
 }
 .gateway-pattern{
  background-image: url(assets/images/gateway-pattern.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
 }
 .gateway-pattern-2{
  background-image: url(assets/images/gateway-pattern.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
 }
 .footer-pattern{
  background-image: url(assets/images/footer-pattern.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
 }
 .pageheader-pattern{
  background-image: url(assets/images/banner-bg.png), url(assets/images/page-header-pattern.png);
  background-repeat: no-repeat, no-repeat;
  background-size: cover, contain;
  background-position: center, top left;
 }
 .search-icon:not(:placeholder-shown) {
  @apply w-[28rem] pl-8 pr-[4.5rem];
}
}
.styles-module_blinkingCursor__yugAC.styles-module_blinking__9VXRT{
  font-weight: 100 !important;
}